import * as React from "react";
import { Link } from "gatsby";

import instagram from "../img/social/instagram.svg";
import linkedin from "../img/social/linkedin.svg";
import mail from "../img/social/mail.svg";
import github from "../img/social/github.svg";

const Footer = class extends React.Component {
  render() {
    return (
      <footer className="footer">
        <hr className="solid"></hr>
        <div className="content has-text-centered">
          <div>
            <div className="columns">
              <div className="column is-4">
              <small>&copy;2022,</small> Zainab Tariq. <small>All rights reserved</small>
              </div>
              <div className="column is-4 social">
                <Link to="/contact">
                  <img
                    src={mail}
                    alt="mail"
                    style={{ width: "1.2em", height: "1.2em" }}
                  />
                </Link>
                <a title="instagram" href="https://www.instagram.com/creativity_abyss/">
                  <img
                    src={instagram}
                    alt="Instagram"
                    style={{ width: "1.2em", height: "1.2em" }}
                  />
                </a>
                <a title="linkedin" href="https://www.linkedin.com/in/zainab-tariq/">
                  <img
                    src={linkedin}
                    alt="linkedin"
                    style={{ width: "1.2em", height: "1.2em" }}
                  />
                </a>
                <a title="Github" href="https://github.com/zainab-tariq">
                  <img
                    src={github}
                    alt="github"
                    style={{ width: "1.2em", height: "1.2em" }}
                  />
                </a>
              </div>
              <div className="column is-4">
              <Link className="" to="/imprint">
                <small>Imprint | Privacy Policy</small>
              </Link>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
};

export default Footer;
